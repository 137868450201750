import * as React from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import { isHttpError } from '@digital-magic/react-common/lib/api'
import { hasValue } from '@digital-magic/ts-common-utils'
import { routes } from '@constants/routes'
import { RequestErrorHandler } from '@api/types'
import { AuthenticationRequest, useAuthentication, useGetSessionInfo, useInvalidateSession } from '@api/endpoints/auth'
import { useDefaultErrorHandler } from '@hooks/useDefaultErrorHandler'
import { CircularProgress } from '@mui/material'

type AdminContext = {
  login: (data: AuthenticationRequest) => void
  logout: () => void
  isLoggedIn: boolean
}

const AdminContext = React.createContext({} as AdminContext)

export const AdminContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const defaultErrorHandler = useDefaultErrorHandler()
  const [isLoggedIn, setLoggedIn] = React.useState<boolean>()

  const isLoginPage = hasValue(useMatch(routes.Admin.Login))

  const onMutationError: RequestErrorHandler = (e) => {
    if (isHttpError(e) && e.httpStatus === 401) {
      setLoggedIn(false)
    } else {
      defaultErrorHandler(e)
    }
  }

  const authenticate = useAuthentication({ onError: onMutationError, onSuccess: () => setLoggedIn(true) })
  const invalidateSession = useInvalidateSession({ onError: onMutationError, onSuccess: () => setLoggedIn(false) })

  useGetSessionInfo({
    onError: () => {
      setLoggedIn(false)
    },
    onSuccess: () => {
      setLoggedIn(true)
    }
  })

  const navigate = useNavigate()

  const login = React.useCallback((data: AuthenticationRequest): void => {
    authenticate.mutate(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const logout = React.useCallback((): void => {
    invalidateSession.mutate()
    setLoggedIn(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (isLoggedIn === true && isLoginPage) {
      navigate(routes.Admin.Index)
    }

    if (isLoggedIn === false && !isLoginPage) {
      navigate(routes.Admin.Login)
    }
  }, [navigate, isLoginPage, isLoggedIn])

  return hasValue(isLoggedIn) ? (
    <AdminContext.Provider value={{ isLoggedIn, login, logout }}>{children}</AdminContext.Provider>
  ) : (
    <CircularProgress />
  )
}

export const useAdminContext = (): AdminContext => React.useContext(AdminContext)
