/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { QueryKey } from '@tanstack/react-query'
import { useApiMutation, useApiQuery } from '@digital-magic/react-common/lib/api/hooks'
import { apiBaseUrl } from '@constants/configuration'
import { ApiMutationOpts, ApiQueryOpts } from '@api/types'
import { callOnly, receiveOnly, sendOnly } from '@api/utils'
import { UnknownResponse } from '@api/endpoints/types'
import { AuthenticationRequest } from './types'

const authUrl = `${apiBaseUrl}/auth`

const getSessionInfoKey: QueryKey = ['getSessionInfo']

export const useAuthentication = (opts?: ApiMutationOpts<void, AuthenticationRequest>) =>
  useApiMutation({
    mutationFn: (data) =>
      sendOnly({
        method: 'post',
        url: authUrl,
        requestSchema: AuthenticationRequest,
        data
      }),
    ...opts
  })

export const useGetSessionInfo = (opts?: ApiQueryOpts<unknown>) =>
  useApiQuery({
    queryFn: () => receiveOnly({ method: 'get', url: authUrl, responseSchema: UnknownResponse }),
    queryKey: getSessionInfoKey,
    ...opts
  })

export const useInvalidateSession = (opts?: ApiMutationOpts<void, void>) =>
  useApiMutation({
    mutationFn: () => callOnly({ method: 'delete', url: authUrl }),
    invalidateQueries: [getSessionInfoKey],
    ...opts
  })
